import { isMobileUA } from 'dpl/shared/utils';

export const EVENT_CATEGORIES = {
  ADVANCE_APPLICATION_STEP: 'Advance Application Step',
  APPLICATION_CTA: 'Application CTA',
  APPLY_TO_BREEDER: 'Apply to Breeder',
  BREEDER_OPEN_SIGNUP_MODAL: 'Breeder Open Signup Modal',
  BREEDER_REQUEST_PAYMENT: 'breeder_payment_request',
  BREEDER_SIGNUP: 'Breeder Signup',
  BREEDER_SUBMIT_APPLICATION: 'Breeder Submit Application',
  BUYER_ADDRESS: 'buyer-address',
  BUYER_AMOUNT: 'buyer-amount',
  BUYER_PAYNOW: 'buyer-paynow',
  FILTER_AVAILABILITY: 'filter_availability',
  FILTER_HEALTH_TESTING: 'filter_health_testing',
  FILTER_LOCATION: 'filter_location',
  FILTER_TRANSPORTATION: 'filter_transportation',
  FOLLOW_BREED: 'follow_breed',
  GENERAL_ENTER_AMOUNT: 'general-enter_amount',
  GENERAL_REQUEST: 'general-request',
  GENERAL_SELECT_CONTACT: 'general-select_contact',
  MESSAGE_CTA: 'Message CTA',
  OVERVIEW_CTA: 'Overview CTA',
  PAYMENT_TAB_CTA: 'Payment Tab CTA',
  PUPPY_DETAILS: 'puppy-details',
  PUPPY_REQUEST: 'puppy-request',
  PUPPY_SELECT_LITTER: 'puppy-select_litter',
  PUPPY_SELECT_PUPPY: 'puppy-select_puppy',
  SEND_MESSAGE: 'Send a Message',
  SUBMIT_APPLICATION: 'Submit an Application',
  WAITLIST_CTA: 'Waitlist CTA'
};

export const EVENT_ACTION_IDS = {
  APPLY_TO_BREEDER: 'AW-810213694/T9FjCNz82ZMBEL7Cq4ID',
  BREEDER_SUBMIT_APPLICATION: 'AW-667038394/21vuCPqDzsMBELrliL4C',
  DEPOSIT_PAYMENT: 'AW-810213694/ZXW2CJSTnaoDEL7Cq4ID',
  DOG_PAYMENT: 'AW-810213694/7GPoCNiLkbQDEL7Cq4ID',
  OTHER_PAYMENT: 'AW-810213694/CK8LCNyU3LMDEL7Cq4ID',
  SEND_MESSAGE: 'AW-810213694/itt1CM_c2JUBEL7Cq4ID',
  SUBMIT_APPLICATION: 'AW-810213694/luBRCN6s5ZUBEL7Cq4ID',
  SUBMIT_INITIAL_INQUIRY: 'AW-810213694/ciVtCLP8haAYEL7Cq4ID'
};

export const BING_CONVERSION_EVENT_ACTIONS = {
  FINAL_PAYMENT: 'Final Payment Completed',
  DEPOSIT_PAYMENT: 'Deposit Payment Completed'
};

/**
 * DEPRECATED: use EVENT_CATEGORIES above instead
 */
export const FACEBOOK_EVENTS = {
  SUBMIT_APPLICATION: 'SubmitApplication'
};

export const ANALYTICS_PROVIDERS = {
  BING: 'Bing',
  FB: 'Facebook',
  GA: 'Google'
};

export const ANALYTICS_EVENTS = {
  ACCOUNT_CREATED_BY_BUYER: 'Account Created By Buyer',
  ADD_LITTER_COMPLETED: 'Add Litter Completed',
  ADD_LITTER_STARTED: 'Add Litter Started',
  BANK_ACCOUNT_AUTHORIZED: 'Bank Account Authorized',
  BREEDER_ADDED_TO_FAVORITES: 'Breeder Added To Favorites',
  BREEDER_PROFILE_VIEWED: 'Breeder Profile Viewed',
  BREEDER_REMOVED_FROM_FAVORITES: 'Breeder Removed From Favorites',
  BREED_SEARCHED: 'Breed Searched',
  BUTTON_CLICKED: 'Button Clicked',
  BUYER_APPLICATION_QUESTION_VIEWED: 'Buyer Application Question Viewed',
  BUYER_APP_STARTED: 'Buyer Application Started',
  BUYER_APP_SUBMITTED: 'Buyer Application Submitted',
  BUYER_PAYMENT_RETURNED: 'Buyer Payment Returned',
  CARD_CLICKED_BY_USER: 'Card Clicked By User',
  CONTACT_QUESTION_VIEWED: 'Contact Question Viewed by User',
  CONTACT_QUESTION_SUBMITTED: 'Contact Question Submitted by User',
  CONTACT_QUIZ_COMPLETED: 'Contact Quiz Completed by User',
  CHECKLIST_ITEM_CLICKED_BY_USER: 'Checklist Item Clicked By User',
  EXPERIMENT_VIEWED_BY_USER: 'Experiment Viewed By User',
  GLADLY_SIDEKICK_CLOSED: 'Gladly Sidekick Closed',
  GLADLY_SIDEKICK_OPENED: 'Gladly Sidekick Opened',
  GLADLY_SIDEKICK_QUICK_ACTION_SELECTED:
    'Gladly Sidekick Quick Action Selected',
  GLADLY_SIDEKICK_SEARCH_RESULT_SELECTED:
    'Gladly Sidekick Search Result Selected',
  INITIAL_INQUIRY_QUESTION_COMPLETED:
    'Initial Inquiry Question Completed By Buyer',
  INITIAL_INQUIRY_QUESTION_VIEWED: 'Initial Inquiry Question Viewed By Buyer',
  INITIAL_INQUIRY_STARTED_BY_BUYER: 'Initial Inquiry Started By Buyer',
  INITIAL_INQUIRY_SUBMITTED_BY_BUYER: 'Initial Inquiry Submitted By Buyer',
  LEAD_CAPTURED: 'Lead Captured',
  LITTER_DETAILS_ADDED: 'Litter Details Submitted',
  LITTER_MODAL_VIEWED: 'Litter Modal Viewed',
  OPEN_IN_APP_BANNER_CLOSED_BY_BREEDER: 'Open In App Banner Closed By Breeder',
  OPEN_IN_APP_BANNER_CLOSED_BY_BUYER: 'Open In App Banner Closed By Buyer',
  OPEN_IN_APP_BANNER_OPENED_BY_BREEDER: 'Open In App Banner Opened By Breeder',
  OPEN_IN_APP_BANNER_OPENED_BY_BUYER: 'Open In App Banner Opened By Buyer',
  OPEN_IN_APP_BANNER_VIEWED_BY_BREEDER: 'Open In App Banner Viewed By Breeder',
  OPEN_IN_APP_BANNER_VIEWED_BY_BUYER: 'Open In App Banner Viewed By Buyer',
  PAYMENT_ACCEPTED_BY_BREEDER: 'Payment Accepted By Breeder',
  PAYMENT_CHECKOUT_STARTED_BY_BUYER: 'Payment Checkout Started by Buyer',
  PAYMENT_CHECKOUT_SUBMITTED_BY_BUYER: 'Payment Checkout Submitted by Buyer',
  PAYMENT_MODAL_VIEWED_BY_BUYER: 'Payment Modal Viewed By Buyer',
  PAYMENT_REQUEST_COMPLETED_BY_BUYER: 'Payment Request Completed By Buyer',
  PAYMENT_REQUEST_FAILED_BY_BUYER: 'Payment Request Failed by Buyer',
  PAYMENT_REQUEST_SENT_BY_BREEDER: 'Payment Request Sent By Breeder',
  PAYMENT_REQUEST_STEP_VIEWED_BY_BREEDER:
    'Payment Request Step Viewed By Breeder',
  PHONE_CALL_PLACED: 'Phone Call Placed',
  PUPPY_VIEWED_BY_BUYER: 'Puppy Viewed By Buyer',
  PUPPY_ADDED_TO_FAVORITES: 'Puppy Added To Favorites',
  PUPPY_DETAILS_ADDED_BY_BREEDER: 'Puppy Details Added By Breeder',
  PUPPY_REMOVED_FROM_FAVORITES: 'Puppy Removed From Favorites',
  MESSAGE_SENT_BY_USER: 'Message Sent By User',
  SEARCH_RESULT_GROUP_VIEWED_BY_USER: 'Search Result Group Viewed by User',
  SEARCH_PAGINATED: 'Search Paginated',
  SOCIAL_POST_INTERACTION_BY_USER: 'Social Post Interaction By User'
};

export const ANALYTICS_USER_JOURNEYS = {
  ACCEPT_PAYMENT_MODAL: 'accept_payment_modal',
  BREEDER_APPLICATION_REVIEW: 'Breeder Application Review',
  BREEDER_DASHBOARD: 'Breeder Dashboard',
  BREEDER_LITTER_SETUP: 'Breeder Litter Setup',
  BREEDER_MESSAGING: 'breeder_messaging',
  BUYER_ACTIVATION: 'Buyer Activation',
  BUYER_BROWSING: 'Buyer Browsing',
  BUYER_DETAILS_DRAWER: 'buyer_details_drawer',
  BUYER_PAYMENT_SETUP: 'Buyer Payment Setup',
  FAMILY_DETAILS_SIDEBAR: 'family_details_sidebar',
  HEADER: 'header',
  JOIN_AS_BREEDER: 'Join as Breeder',
  MESSAGES_BUYER_PANEL: 'messages_buyer_panel',
  NEW_MESSAGE: 'new_message',
  PAYMENTS: 'Payments',
  PAYMENTS_BIP: 'Payments (BIP)',
  PAYMENTS_REQUEST_BUYER: 'Payments (Request - Buyer)',
  SCREENING_QUIZ: 'Screening Quiz',
  WAITLIST_TABLE: 'waitlist_table'
};

export const ANALYTICS_VIEWS = {
  BREED_GUIDE_SIGNUP: 'breed_guide_signup',
  BREEDER_DASHBOARD_APPLICATIONS: 'breeder_dashboard_applications',
  BREEDER_DASHBOARD_LITTERS: 'breeder_dashboard_litters',
  BREEDER_DASHBOARD_MESSAGES: 'breeder_dashboard_messages',
  BREEDER_DASHBOARD_MESSENGER: 'breeder_dashboard_messenger',
  BREEDER_DASHBOARD_OVERVIEW: 'breeder_dashboard_overview',
  BREEDER_DASHBOARD_PAST_FAMILIES: 'breeder_dashboard_past_families',
  BREEDER_DASHBOARD_PAYMENTS: 'breeder_dashboard_payments',
  BREEDER_DASHBOARD_WAITLIST: 'breeder_dashboard_waitlist',
  BREEDER_INSIGHTS_ANALYTICS: 'breeder_insights_analytics',
  BREEDER_INSIGHTS_OPPORTUNITIES: 'breeder_insights_opportunities',
  BREEDER_INSIGHTS_STORIES: 'breeder_insights_stories',
  BREEDER_PROFILE: 'breeder_profile',
  BREEDER_PROFILE_PAGE: 'breeder_profile_page',
  BREEDER_SEARCH_RESULTS: 'breeder_search_results',
  BREEDER_UPDATES_MODAL: 'breeder_updates_modal',
  BUYER_APPLICATION_SUCCESS_PAGE: 'buyer_application_success_page',
  BUYER_APPLICATION: 'buyer_application',
  BUYER_DASHBOARD_MESSENGER: 'buyer_dashboard_messenger',
  BUYER_DASHBOARD_PAYMENTS: 'buyer_dashboard_payments',
  BUYER_PAYMENT_SUMMARY: 'buyer_payment_summary',
  CONCIERGE_SIGNUP: 'concierge_signup',
  CONSUMER_APP_DOWNLOAD_MODAL: 'consumer_app_download_modal',
  CONTACT_QUIZ: 'contact_quiz',
  DOG_FOOD_MODAL: 'dog_food_modal',
  FAVORITE_BREEDERS_PAGE: 'favorite_breeders_page',
  FAVORITE_PUPPIES_PAGE: 'favorite_puppies_page',
  HOME_PAGE: 'home_page',
  INITIAL_INQUIRY_SUBMITTED: 'initial_inquiry_submitted',
  INPUT_PAYMENT_DETAILS_PAGE: 'input_payment_details_page',
  INSURANCE_MESSAGE_MODAL: 'insurance_message_modal',
  INSURANCE_REFERRAL_PUPPY_NAME: 'insurance-referral-puppy-name',
  LITTER_MODAL: 'litter_modal',
  LITTER_BORN_FLOW: 'born_litter_flow',
  LITTER_EXPECTED_FLOW: 'expected_litter_flow',
  LITTER_PLANNED_FLOW: 'planned_litter_flow',
  MATCH_PUPPY_MODAL: 'match_puppy_modal',
  MESSAGE_COMPOSER: 'message_composer',
  MESSENGER_TO_DO: 'messenger_to_do',
  NEW_LITTER_FLOW: 'new_litter_flow',
  PAYMENT_DETAILS: 'payment_details',
  PAYMENT_POLICY_MESSAGE_MODAL: 'payment_policy_message_modal',
  PAYMENT_REQUEST_AMOUNT_SCREEN: 'payment_request_amount_screen',
  PAYMENT_REQUEST_SUCCESS_PAGE_V2: 'payment_request_success_page_v2',
  PET_INSURANCE_PAGE: 'pet_insurance_page',
  PROTECTION_SUPPORT_CHANGE_CONFIRMATION:
    'protection_support_change_confirmation',
  PROTECTION_SUPPORT_CHANGE_SELECTION: 'protection_support_change_selection',
  PROTECTION_SUPPORT_LEARN_MORE: 'protection_support_learn_more',
  PROTECTION_SUPPORT_QUICK_VIEW: 'protection_support_quick_view',
  PUPPY_MODAL: 'puppy_modal',
  PUPPY_PROFILE: 'puppy_profile',
  PUPPY_SEARCH_RESULTS: 'puppy_search_results',
  REQUEST_OWNER_STORY_MODAL: 'request_owner_story_modal',
  SAVED_SEARCH: 'saved_search',
  SEARCH_LANDING_PAGE: 'search_landing_page',
  SEARCH_RESULTS: 'search_results',
  SIRE_DAM_MODAL: 'sire_dam_modal',
  STEPS_TO_IMPROVE: 'steps_to_improve',
  TODO: 'todo'
};

export const ANALYTICS_VIEW_FEATURES = {
  ACCEPT_PAYMENT_BUTTON: 'accept_payment_button',
  ADD_LITTER_BUTTON: 'add_litter_button',
  ADD_LITTER_DROPDOWN: 'add_litter_dropdown',
  ANNOUNCE_BIRTH_BUTTON: 'announce_birth_button',
  APPLY_BUTTON: 'apply_button',
  APPLY_TO_BREEDER_BUTTON: 'apply_to_breeder_button',
  ASK_A_QUESTION_BUTTON: 'ask_a_question_button',
  ASSIGN_TO_LITTER_BUTTON: 'assign_to_litter_button',
  ASSIGN_TO_PUPPY_BUTTON: 'assign_to_puppy_button',
  BREED_GUIDE_CALLOUT: 'breed_guide_callout',
  BREEDER_CARD: 'breeder_card',
  BREEDER_CHECKLIST: 'breeder_checklist',
  BREEDER_REVIEWS_LINK: 'breeder_reviews_link',
  BREEDER_REVIEWS_SHOW_MORE_LINK: 'breeder_reviews_show_more_link',
  BREEDERS_NEAR_YOU_MODULE: 'breeders_near_you_module',
  BREEDERS_NEAR_YOU: 'breeders_near_you',
  BROWSE_BY_ATTRIBUTE: 'browse_by_attribute',
  BUYER_CHECKLIST: 'buyer_checklist',
  BUYER_DETAILS_DRAWER: 'buyer_details_drawer',
  BUYER_INITIATED_PAYMENT: 'buyer_initiated_payment',
  CANCEL_REQUEST_BUTTON: 'cancel_request_button',
  CHECKLIST_ITEM_CAROUSEL: 'checklist_item_carousel',
  COAT_TYPE_FILTER_BUTTON: 'coat_type_filter_button',
  COLOR_FILTER_BUTTON: 'color_filter_button',
  COMPLETE_REQUEST_BUTTON: 'complete_request_button',
  CONCIERGE_CALLOUT: 'concierge_callout',
  CONSUMER_APP_DOWNLOAD_BANNER: 'consumer_app_download_banner',
  CONSUMER_APP_DOWNLOAD_IMAGE: 'consumer_app_download_image',
  CONSUMER_APP_STORE_LINK: 'consumer_app_store_link',
  CREATE_ASSIGNMENT_BUTTON: 'create_assignment_button',
  EDIT_ASSIGNMENT_BUTTON: 'edit_assignment_button',
  EDIT_BUYER_CONTACT_CARD: 'edit_buyer_contact_card',
  EXPLORE_THE_OFFER_BUTTON: 'explore_the_offer_button',
  FAVORITE_HEART_ICON: 'favorite_heart_icon',
  FAVORITES: 'favorites',
  FOLLOW_LINK: 'follow_link',
  GENDER_FILTER_BUTTON: 'gender_filter_button',
  GEO_SEARCH: 'geo_search',
  GO_HOME_DATE_FILTER_BUTTON: 'go_home_date_filter_button',
  HEALTH_TESTING_FILTER_BUTTON: 'health_testing_filter_button',
  HEART_ICON: 'heart_icon',
  AGE_FILTER_BUTTON: 'age_filter_button',
  INITIAL_INQUIRY_BROWSE_MORE: 'initial_inquiry_browse_more_button',
  INITIATE_REFUND_BUTTON: 'initiate_refund_button',
  INSURANCE_REMINDER_BUTTON: 'insurance_reminder_button',
  LOCATION_FILTER_BUTTON: 'location_filter_button',
  LEARN_MORE_HYPERLINK: 'learn_more_hyperlink',
  LITTER_CARD: 'litter_card',
  MEDIA_ATTACHMENT_BUTTON: 'media_attachment_button',
  MESSENGER_COMPOSER_REQUEST_PAYMENT_BUTTON:
    'messenger_composer_request_payment_button',
  MESSENGER_COMPOSER_REQUEST_PAYMENT_ICON:
    'messenger_composer_request_payment_icon',
  MESSENGER_PAYMENT_CARD: 'messenger_payment_card',
  MESSENGER_TABS: 'messenger_tabs',
  NEWSLETTER_SIGNUP: 'newsletter_signup',
  ONE_CLICK_APPLY: 'one_click_apply',
  PAY_BREEDER_BUTTON: 'pay_breeder_button',
  PAY_BREEDER_ICON: 'pay_breeder_icon',
  PAY_INVOICE: 'pay_invoice_button',
  PAY_INVOICE_LATER: 'pay_invoice_later_button',
  PAY_IT_BUTTON: 'pay_it_button',
  PAYMENT_CHECKOUT_PAGE: 'payment_checkout_page',
  PENDING_TRANSACTIONS: 'pending_transactions',
  PRICE_BREAKDOWN_BUTTON: 'price_breakdown_button',
  PRICE_FILTER_BUTTON: 'price_filter_button',
  PUPPY_CARD: 'puppy_card',
  PUPPY_DETAILS_BUTTON: 'puppy_details_button',
  PUPPY_MATCH_UPDATES: 'puppy_match_updates',
  REQUEST_INFO_BUTTON: 'request_info_button',
  REQUEST_PAYMENT_BUTTON: 'request_payment_button',
  REQUEST_PAYMENT_ICON: 'request_payment_icon',
  RETURN_PAYMENT_BUTTON: 'return_payment_button',
  REVIEW_AND_ACCEPT_BUTTON: 'review_and_accept_button',
  REVIEW_AND_COMPLETE_REQUESTS_BUTTON: 'review_and_complete_requests_button',
  REVIEW_APPLICATION_BUTTON: 'review_application_button',
  SEARCH_BREED_NEAR_YOU_BUTTON: 'search_breed_near_you_button',
  SEARCH_BREED_NEAR_YOU_CARD: 'search_breed_near_you_card',
  SEND_PAYMENT_BUTTON: 'send_payment_button',
  SHARE_ICON: 'share_icon',
  SHARE_INSURANCE_BUTTON: 'share_insurance_button',
  SHARE_PAYMENT_POLICY_BUTTON: 'share_payment_policy_button',
  SIZE_FILTER_BUTTON: 'size_filter_button',
  SORT_FILTER_BUTTON: 'sort_filter_button',
  STEPS_TO_IMPROVE_HYPERLINK: 'steps_to_improve_hyperlink',
  SUBMIT_BUTTON: 'submit_button',
  SUCCESSFUL_SUBMISSION: 'successful_submission',
  VIEW_DETAILS_BUTTON: 'view_details_button',
  VIEW_NEARBY_BREED_RESULTS_BUTTON: 'view_nearby_breed_results_button',
  VIEW_OPPORTUNITIES_HYPERLINK: 'view_opportunities_hyperlink',
  VIEW_PAYMENTS_BUTTON: 'view_payments_button',
  VIEW_PROFILE_BUTTON: 'view_profile_button',
  VIEW_REQUEST_BUTTON: 'view_request_button',
  VIEW_PUPPIES_BUTTON: 'view_puppies_button',
  VIEW_PUPPY_HYPERLINK: 'view_puppy_hyperlink',
  VO_STORIES_MODULE: 'vo_stories_module',
  WITHDRAW_APPLICATION_BUTTON: 'withdraw_application_button',
  WITHDRAW_INITIAL_INQUIRY_BUTTON: 'withdraw_initial_inquiry_button'
};

export const ANALYTICS_VIEW_FEATURE_LOCATIONS = {
  ABOUT_BREED_MODULE: 'about_breed_module',
  AVAILABLE_PUPPIES: 'available_puppies',
  BOTTOM_NAVBAR: 'bottom_navbar',
  BREEDER_CAROUSEL: 'breeder_carousel',
  BREEDERS_NEAR_YOU_CAROUSEL: 'breeders_near_you_carousel',
  BREEDER_PROFILE_HEADER: 'breeder_profile_header',
  BREEDER_PROFILE_ICON: 'breeder_profile_icon',
  BREEDER_PROFILE_NAME: 'breeder_profile_name',
  BREEDER_PROFILE_OVERVIEW: 'breeder_profile_overview',
  BREEDER_PROFILE_PUPPIES_CAROUSEL: 'breeder_profile_puppies_carousel',
  BREEDER_PROFILE_STICKY_BOTTOM: 'breeder_profile_sticky_bottom',
  BUYER_INITIATED_PAYMENT: 'buyer_initiated_payment',
  CTA: 'cta',
  EXPLORE_BREED_BY_STATE_MODULE: 'explore_breed_by_state_module',
  FAVORITES: 'favorites',
  HEADER: 'header',
  HEALTH_TAB: 'health_tab',
  HERO: 'hero',
  FOOTER: 'footer',
  LITTERS_AND_PUPPIES_TAB: 'litters_and_puppies_tab',
  MESSAGE_THREAD: 'message_thread',
  MESSENGER_COMPOSER: 'messenger_composer',
  MESSENGER_COMPOSER_ACTION_BAR: 'messenger_composer_action_bar',
  MESSENGER_SIDEBAR: 'messenger_sidebar',
  MORE_ACTIONS_PLUS_ICON: 'more_actions_plus_icon',
  NO_AVAILABLE_PUPPIES: 'no_available_puppies',
  OVERVIEW_TAB: 'overview_tab',
  OWNER_STORIES_SECTION: 'owner_stories_section',
  OWNER_STORIES_TAB: 'owner_stories_tab',
  PAGE_HEADER: 'page_header',
  PAST_PUPPIES: 'past_puppies',
  PAYMENT_CHECKOUT_PAGE: 'payment_checkout_page',
  PAY_LATER_FORM: 'pay_later_form',
  PAYMENTS_DASHBOARD: 'payments_dashboard',
  PEDIGREE_TAB: 'pedigree_tab',
  PHOTO_CAROUSEL: 'photo_carousel',
  PUPPY_CAROUSEL_SEARCH_CARD: 'puppy_carousel_search_card',
  PUPPY_DETAILS: 'puppy_details',
  PUPPY_DETAILS_STICKY_SIDE: 'puppy_details_sticky_side',
  PUPPY_OVERVIEW: 'puppy_overview',
  PUPPY_PROFILE_HEADER: 'puppy_profile_header',
  PUSHDOWN: 'pushdown',
  RECOMMENDATIONS: 'recommendations',
  RECOMMENDED_NEARBY_PUPPIES: 'recommended_nearby_puppies',
  RECOMMENDED_NEARBY_BREEDERS: 'recommended_nearby_breeders',
  REFUND_MODAL: 'refund_modal',
  REFUND_MODAL_INTERSTITIAL: 'refund_modal_interstitial',
  REVIEWS_FROM_OWNERS: 'reviews_from_owners',
  REVIEWS_LINK: 'reviews_link',
  SEARCH_HEADER: 'search_header',
  SEARCH_RESULTS: 'search_results',
  SIBLINGS_CAROUSEL: 'siblings_carousel',
  STICKY_HEADER: 'sticky_header',
  STICKY_TOP: 'sticky_top',
  STICKY_BOTTOM: 'sticky_bottom',
  STICKY_BUTTON: 'sticky_button',
  TIPS_COMPLETE_LISTINGS: 'tips_complete_listings',
  TIPS_HEALTH_TESTING: 'tips_health_testing',
  TIPS_IMPROVE_LISTING: 'tips_improve_listing',
  TIPS_INCREASE_VIEWS: 'tips_increase_views',
  TIPS_OWNER_STORIES: 'tips_owner_stories',
  TIPS_PLACEMENT_RATE: 'tips_placement_rate',
  TOP_PERFORMING_LISTINGS: 'top_performing_listings',
  UPCOMING_LITTERS: 'upcoming_litters',
  UPDATES_SECTION: 'updates_section',
  UPDATES_TAB: 'updates_tab',
  WAITLIST_MEMBER: 'waitlist_member'
};

export const ANALYTICS_INTERACTION_TYPES = {
  FOLLOW: 'follow',
  LIKE: 'like',
  SHARE: 'share',
  UNLIKE: 'unlike'
};

export const ANALYTICS_PAGE_NAMES = {
  PUPPY_PROFILE: 'Puppy profile'
};

export const ANALYTICS_PLATFORM = isMobileUA() ? 'mobile_web' : 'web';
